<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="6" align="center" class="pa-0">
                <v-img :src="imageFacility1" :key="imageFacility1"></v-img>
            </v-col>
            <v-col cols="6" class="pa-0">
                <v-card height="100%" class="d-flex align-center" flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="9" offset-md="3">
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                    class="font-weight-light"
                                >
                                    美容室のような
                                    <br v-show="smAndUp" />
                                    <br />

                                    <span class="red--text">オシャレ</span
                                    >で<br />
                                    <br v-show="smAndUp" />

                                    ナチュラルな施設
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-0">
                <v-card height="100%" class="d-flex align-center" flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="9" offset-md="3">
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                    class="font-weight-light"
                                >
                                    業界<span class="red--text">最高基準</span>
                                    <br v-show="smAndUp" />
                                    <br />

                                    高品質<br /><br
                                        v-show="smAndUp"
                                    />ウェイトマシン
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img :src="imageFacility2" :key="imageFacility2"></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageFacility1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/11@2x.png'
                : 'static/landing/sp/gym_img01@2x.png'
        },
        imageFacility2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/12@2x.png'
                : 'static/landing/sp/gym_img02@2x.png'
        },
    },
}
</script>

<style lang="scss" scoped></style>
