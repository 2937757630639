<template>
    <v-container class="my-10">
        <v-row justify="center">
            <v-col cols="12" md="12" align="center"> </v-col>
            <v-col cols="auto" align="left">
                <div
                    class="font-weight-light"
                    :class="
                        smAndUp
                            ? 'text-h4 my-4 '
                            : 'text-body-1 font-weight-bold my-4'
                    "
                >
                    <span class="under"
                        >入会金<span
                            class="red--text"
                            :class="smAndUp ? 'text-h3 ' : 'text-h5'"
                            >０</span
                        >円</span
                    >/
                    <span class="under"
                        >事務手数料<span
                            class="font-weight-thin red--text"
                            :class="smAndUp ? 'text-h3 ' : 'text-h5'"
                            >０</span
                        >円</span
                    >/
                    <span class="under"
                        >解約金<span
                            class="font-weight-thin red--text"
                            :class="smAndUp ? 'text-h3 ' : 'text-h5'"
                            >０</span
                        >円</span
                    >
                </div>
            </v-col>
            <v-col cols="12" md="10" align="center">
                <div
                    class="font-weight-light"
                    :class="
                        smAndUp
                            ? 'text-h4 my-4 '
                            : 'text-body-1 font-weight-medium'
                    "
                >
                    パーソナルトレーニング業界への挑戦。<br />
                    <br />
                    １人でも多くの人がパーソナルトレーニングを<br />
                    長く続けられる、受けられる値段を設定。
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {},
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.under {
    background: linear-gradient(transparent 70%, #f6cfcf 70%);
}
</style>
