<template>
    <v-row>
        <!-- 店舗個別メッセージ -->
        <v-col
            cols="12"
            md="3"
            offset-md="2"
            align="right"
            :class="smAndUp ? 'px-0' : 'px-0'"
        >
            <v-img
                :src="shopImage"
                :key="shopImage"
                :height="smAndUp ? '30vh' : '40vh'"
                contain
            ></v-img>
        </v-col>
        <v-col
            cols="12"
            md="6"
            :class="smAndUp ? 'py-8 d-flex align-center' : 'px-12'"
        >
            <div v-html="message"></div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        message: {
            required: true,
            type: String,
            default: () => 'test message',
        },
        shopImage: {
            required: true,
            type: String,
            default: () => 'static/landing/pc/IMG_9986@2x.png',
        },
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped></style>
