<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    :src="imageVoc1_1"
                    :key="imageVoc1_1"
                    :height="smAndUp ? '40vh' : '20vh'"
                >
                </v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    class="trim"
                    :src="imageVoc1_2"
                    :key="imageVoc1_2"
                    :height="smAndUp ? '40vh' : '20vh'"
                >
                    <div class="layer">
                        <div
                            :class="
                                smAndUp
                                    ? 'inner-text-wrapper'
                                    : 'inner-text-wrapper-sm'
                            "
                        >
                            <div
                                class="font-weight-normal inner-text"
                                :class="smAndUp ? 'text-h5' : 'text-body-2'"
                                align="left"
                            >
                                <span class="blue--text">
                                    20代女性 A.Yさん</span
                                >
                                <br />
                                <br v-show="smAndUp" />

                                <div
                                    :class="
                                        smAndUp
                                            ? 'text-h5'
                                            : 'text-caption pt-2'
                                    "
                                >
                                    顔が小さくなって、小さな服も着られるようになりました！
                                </div>
                            </div>
                        </div>
                        <div
                            :class="smAndUp ? 'blue-line my-6' : 'blue-line-sm'"
                        ></div>
                    </div>
                </v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    class="trim"
                    :src="imageVoc2_2"
                    :key="imageVoc2_2"
                    :height="smAndUp ? '40vh' : '20vh'"
                >
                    <div class="layer">
                        <div
                            :class="
                                smAndUp
                                    ? 'inner-text-wrapper'
                                    : 'inner-text-wrapper-sm'
                            "
                        >
                            <div
                                class="font-weight-normal inner-text"
                                :class="smAndUp ? 'text-h5' : 'text-subtitle-2'"
                                align="left"
                            >
                                <span class="blue--text">
                                    30代女性 M.Kさん</span
                                >
                                <br />
                                <br v-show="smAndUp" />

                                <div
                                    :class="
                                        smAndUp
                                            ? 'text-h5'
                                            : 'text-caption pt-2'
                                    "
                                >
                                    着てみたい服やお出かけしたい場所がたくさん増えました！
                                </div>
                            </div>
                        </div>
                        <div
                            :class="smAndUp ? 'blue-line my-6' : 'blue-line-sm'"
                        ></div>
                    </div>
                </v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    :src="imageVoc2_1"
                    :key="imageVoc2_1"
                    :height="smAndUp ? '40vh' : '20vh'"
                ></v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    :src="imageVoc3_1"
                    :key="imageVoc3_1"
                    :height="smAndUp ? '40vh' : '20vh'"
                ></v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    class="trim2"
                    :src="imageVoc3_2"
                    :key="imageVoc3_2"
                    :height="smAndUp ? '40vh' : '20vh'"
                >
                    <div class="layer">
                        <div
                            :class="
                                smAndUp
                                    ? 'inner-text-wrapper'
                                    : 'inner-text-wrapper-sm'
                            "
                        >
                            <div
                                class="font-weight-normal inner-text"
                                :class="smAndUp ? 'text-h5' : 'text-subtitle-2'"
                                align="left"
                            >
                                <span class="blue--text">
                                    40代女性 A.Mさん</span
                                >
                                <br />
                                <br v-show="smAndUp" />

                                <div
                                    :class="
                                        smAndUp
                                            ? 'text-h5'
                                            : 'text-caption pt-2'
                                    "
                                >
                                    40代の女性には絶対にオススメしたいです！
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        :class="smAndUp ? 'blue-line my-6' : 'blue-line-sm'"
                    ></div>
                </v-img>
            </v-col>
            <v-col cols="12" md="6" align="left" class="align-self-center">
                <div
                    class="font-weight-light"
                    :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6'"
                    :align="smAndUp ? '' : 'center'"
                >
                    <span style="line-height: 1.5em;">
                        効果が実感できない<br />運動を続けられない、<br />
                        カラダを変えられない
                    </span>
                    <br /><br />そんな<span class="red--text">あなた</span
                    >へ<br /><br />
                </div>
                <div
                    :class="smAndUp ? 'text-body-1 my-4 ' : 'text-body-2 mx-4'"
                    :style="smAndUp ? 'width: 80%;' : ''"
                >
                    経験豊富なREVOISTのパーソナルトレーナーが科学の知見に基づき体の悩みを解決します。
                </div>
            </v-col>
            <v-col cols="12" md="6" align="center" class="pa-0">
                <v-img :src="imageMethods" :key="imageMethods"></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageVoc1_1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial/4B7A8691@2x.png'
                : 'static/sp_testimonial–1/testimonial01_img01@2x.png'
        },
        imageVoc1_2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–1/4B7A8760@2x.png'
                : 'static/sp_testimonial–1/testimonial01_img04@2x.png'
        },
        imageVoc2_1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–2/4B7A8859@2x.png'
                : 'static/sp_testimonial–2/testimonial01_img01@2x.png'
        },
        imageVoc2_2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–2/4B7A8927@2x.png'
                : 'static/sp_testimonial–2/testimonial01_img04@2x.png'
        },
        imageVoc3_1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–3/4B7A9028@2x.png'
                : 'static/sp_testimonial–3/testimonial01_img01@2x.png'
        },
        imageVoc3_2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–3/4B7A9251@2x.png'
                : 'static/sp_testimonial–3/testimonial01_img04@2x.png'
        },
        imageMethods() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/メソッド@2x.png'
                : 'static/landing/sp/method_img@2x.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.inner-text-wrapper {
    position: absolute;
    display: table;
    top: 55%;
    left: 5%;
    opacity: 0.9;
    z-index: 9;

    .inner-text {
        display: table-cell;
        vertical-align: middle;
        color: black;
    }
}
.inner-text-wrapper-sm {
    position: absolute;
    display: table;
    top: 40%;
    left: 3%;
    opacity: 0.9;
    z-index: 9;

    .inner-text {
        display: table-cell;
        vertical-align: middle;
        color: black;
    }
}

.blue-line {
    background: #0034c3 0% 0% no-repeat padding-box;
    height: 2vh;
    width: 100%;
    opacity: 0.3;
    z-index: 9;
    top: 60%;
    position: absolute;
}
.blue-line-sm {
    background: #0034c3 0% 0% no-repeat padding-box;
    height: 0.5vh;
    width: 100%;
    opacity: 0.3;
    z-index: 9;
    top: 55%;
    position: absolute;
}
.layer {
    display: block;
    background-color: #ffffff;
    position: relative;
    min-width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 8;
}
.trim.v-image ::v-deep .v-image__image {
    background-position: 50% 15% !important;
}
.trim2.v-image ::v-deep .v-image__image {
    background-position: 50% 30% !important;
}
</style>
