<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" align="center">
                <div :class="smAndUp ? 'text-h3' : 'text-h5 font-weight-light'">
                    <span class="red--text">こんな方</span>にオススメ！
                </div>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-card
                    height="100%"
                    outlined
                    class="d-flex align-center"
                    :class="
                        smAndUp ? 'recommend-border' : 'recommend-border-sm'
                    "
                >
                    <v-row>
                        <v-col cols="12" md="9" offset-md="2">
                            <v-card-text align="left">
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                >
                                    <span class="red--text">仕事が忙しい</span>
                                    <br />20～30代の働く女性
                                    <br v-show="smAndUp" />
                                    <br v-show="smAndUp" />
                                </div>
                                <div
                                    :class="
                                        smAndUp ? 'text-h5' : 'text-caption'
                                    "
                                >
                                    週一回で効果が期待できます！
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    :src="imageRecommend1"
                    :key="imageRecommend1"
                    height="100%"
                ></v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    :src="imageRecommend2"
                    :key="imageRecommend2"
                    height="100%"
                ></v-img>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-card
                    height="100%"
                    outlined
                    class="d-flex align-center"
                    :class="
                        smAndUp ? 'recommend-border' : 'recommend-border-sm'
                    "
                >
                    <v-row>
                        <v-col cols="12" md="9" offset-md="2">
                            <v-card-text align="left">
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-2'"
                                >
                                    <span class="red--text"
                                        >体系が気になりだした</span
                                    >
                                    <br v-show="smAndUp" />30代～40代男性
                                    <br v-show="smAndUp" />
                                    <br v-show="smAndUp" />
                                </div>
                                <div
                                    :class="
                                        smAndUp ? 'text-h5' : 'text-caption'
                                    "
                                >
                                    出費が多い年代ですが、
                                    <br v-show="smAndUp" />
                                    適正料金で安心です。
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-card
                    height="100%"
                    outlined
                    class="d-flex align-center"
                    :class="
                        smAndUp ? 'recommend-border' : 'recommend-border-sm'
                    "
                >
                    <v-row>
                        <v-col cols="12" md="9" offset-md="2">
                            <v-card-text align="left">
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                >
                                    <span class="red--text">他のジムで</span>
                                    <br />続かなかった方<br /><br />
                                </div>
                                <div
                                    :class="
                                        smAndUp ? 'text-h5' : 'text-caption'
                                    "
                                >
                                    駅近の立地で通いやすく<br />継続していただけます。
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="6" align="center" class="pa-0">
                <v-img
                    :src="imageRecommend3"
                    :key="imageRecommend3"
                    height="100%"
                ></v-img>
            </v-col>
            <v-col cols="12" md="8" class="ma-4">
                <div :class="smAndUp ? 'text-body-1' : 'text-body-2'">
                    健康管理が目的の方や、スポーツをされている方など、どなたでも安心してトレーニングできますのでお気軽にお問合せください。
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },

        imageRecommend1() {
            return this.smAndUp
                ? '/static/landing/pc/img_about03_01@2x.png'
                : '/static/landing/sp/img_about03_01@2x.png'
        },
        imageRecommend2() {
            return this.smAndUp
                ? '/static/landing/pc/img_about03_02@2x.png'
                : '/static/landing/sp/img_about03_02@2x.png'
        },
        imageRecommend3() {
            return this.smAndUp
                ? '/static/landing/pc/img_about03_03@2x.png'
                : '/static/landing/sp/img_about03_03@2x.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.recommend-border {
    border-width: 0.8em;
    border-color: #f3e3b6;
}
.recommend-border-sm {
    border-width: 0.2em;
    border-color: #f3e3b6;
}
</style>
