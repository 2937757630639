<template>
    <v-container class="background">
        <div class="d-flex justify-center ma-4">
            <div
                :class="smAndUp ? 'round-cell' : 'round-cell-sm'"
                align="center"
            >
                <div
                    :class="smAndUp ? 'text-h4' : 'text-h6'"
                    class="font-weight-light"
                >
                    <p :class="smAndUp ? 'ma-6' : 'ma-auto'">
                        トレーナーの<span class="blue--text">こだわり</span>
                    </p>
                </div>
            </div>
        </div>
        <v-row justify="center">
            <v-col cols="6" class="pa-0">
                <v-card
                    flat
                    height="100%"
                    class="d-flex align-center"
                    color="transparent"
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8" offset-md="3">
                                <div
                                    :class="
                                        smAndUp ? 'text-h5' : 'text-caption'
                                    "
                                    class="font-weight-normal"
                                >
                                    指導経験豊富で<br />親切なトレーナー。<br /><br />
                                    お悩みや日常の会話を楽しみながら、<br />
                                    ひとりひとりの価値観に<br />寄り添ったレッスン。
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" align="center" :class="smAndUp ? 'pa-16' : ''">
                <v-img :src="imageCommitment1" :key="imageCommitment1"></v-img>
            </v-col>
            <v-col
                cols="5"
                md="6"
                align="center"
                :class="smAndUp ? 'pa-16' : ''"
            >
                <v-img :src="imageCommitment2" :key="imageCommitment2"></v-img>
            </v-col>
            <v-col cols="7" md="6" class="pa-0">
                <v-card
                    flat
                    height="100%"
                    class="d-flex align-center"
                    color="transparent"
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8" offset-md="3">
                                <div
                                    :class="
                                        smAndUp ? 'text-h5' : 'text-caption'
                                    "
                                    class="font-weight-normal"
                                >
                                    ひとりでも多くの人にとって<br />
                                    パーソナルトレーニングを<br />
                                    受けやすく、続けやすいサービスへ<br /><br />

                                    株式会社REVOIST<br />代表取締役 井上 大輔
                                    <br />
                                    <br v-show="smAndUp" />
                                    東京大学大学院身体運動科学科卒<br />
                                    パーソナルトレーナー
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },

        imageCommitment1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/IMG_9986-1@2x.png'
                : 'static/landing/sp/IMG_9986@2x.png'
        },
        imageCommitment2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/白衣先生@2x.png'
                : 'static/landing/sp/trainer_img02.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.round-cell {
    background-color: #ffffff;
    border-radius: 91px;
    width: 30vw;
    .p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    position: relative;
}
.round-cell-sm {
    background-color: #ffffff;
    border-radius: 91px;
    width: 80vw;
    .p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    position: relative;
}

.background {
    background: transparent
        linear-gradient(143deg, #0abafaf9 0%, #76fabdf9 100%) 0% 0% no-repeat
        padding-box;
}
</style>
