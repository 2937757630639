<template>
    <v-container class="my-10">
        <v-row justify="center">
            <v-col
                cols="6"
                md="3"
                align="center"
                class="pa-1"
                v-for="item in prices"
                :key="item.type"
            >
                <v-row justify="center" class="ma-auto">
                    <v-col cols="auto pa-0">
                        <p class="mb-0">
                            <span
                                class="bluetext"
                                :class="smAndUp ? 'text-h4' : 'text-h5'"
                                >{{ item.type }}</span
                            >会員
                        </p>
                        <span>{{ item.desc }}</span>
                    </v-col>
                    <v-col cols="12" class="outer-price-board pa-0">
                        <div class="blue lighten-4 ma-2 price-board">
                            <p
                                :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                                class="my-0"
                            >
                                {{ item.unit }}
                            </p>
                            <p
                                :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                                class="my-0"
                            >
                                {{ item.detail }}
                            </p>
                            <p :class="smAndUp ? 'text-body-1' : 'text-body-2'">
                                <span
                                    class="bluetext"
                                    :class="smAndUp ? 'text-h4' : 'text-h6'"
                                    >{{ item.price }}</span
                                >(税抜)
                            </p>
                        </div>
                    </v-col>
                    <v-col align="left">
                        <div class="text-caption" v-if="!!item.note">
                            ※週1会員or週2会員を3ヶ月以上継続した方専用のアフタープランです。
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            prices: [
                {
                    type: '週1',
                    desc: '(月4回プラン)',
                    unit: '月会費',
                    detail: '1回 50分 4,980円×4回',
                    price: ' 19,920円',
                },
                {
                    type: '週2',
                    desc: '(月8回プラン)',
                    unit: '月会費',
                    detail: '1回 50分 4,980円×8回',
                    price: ' 39,840円',
                },
                {
                    type: '卒業生',
                    desc: '(都度プラン)',
                    unit: '1回 50分',
                    detail: '',
                    price: ' 5,980円',
                    note:
                        '※週1会員or週2会員を3ヶ月以上継続した方専用のアフタープランです。',
                },
                {
                    type: 'ビジター',
                    desc: '(都度プラン)',
                    unit: '1回 50分',
                    detail: '',
                    price: ' 6,980円',
                },
            ],
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.outer-price-board {
    display: table;
    width: 100%;
    min-height: 15vh;
    .price-board {
        display: table-cell;
        vertical-align: middle;
    }
}
.bluetext {
    color: #0034c3;
}
.title {
    display: block !important;
}
</style>
