<template>
    <v-container :class="smAndUp ? 'mb-16' : ''">
        <v-sheet
            :style="smAndUp ? 'background-color: #F7FAFD' : ''"
            class="pb-16"
        >
            <v-row justify="center" class="d-flex flex-row">
                <v-col cols="6" align="center" class="pa-0 d-flex flex-column">
                    <v-sheet
                        :style="smAndUp ? 'background-color: #F7FAFD' : ''"
                    >
                        <v-img
                            src="static/landing/sp/icon_q.svg"
                            class="question d-flex align-center"
                            contain
                            :height="smAndUp ? '40vh' : '20vh'"
                        >
                            <v-card-text>
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                    class="font-weight-light"
                                >
                                    ジムは初めてですが<br />大丈夫ですか？
                                </div>
                            </v-card-text>
                        </v-img>
                        <v-card-text
                            class="d-flex align-center justify-center"
                            :class="
                                smAndUp ? 'arrow_box_top' : 'arrow_box_top_sm'
                            "
                        >
                            <div
                                :class="smAndUp ? 'text-h5' : 'text-caption'"
                                class="font-weight-normal"
                            >
                                ご心配は<br />一切ありません！
                            </div>
                        </v-card-text>
                    </v-sheet>
                    <v-sheet
                        :style="smAndUp ? 'background-color: #F7FAFD' : ''"
                    >
                        <v-img
                            src="static/landing/sp/icon_q.svg"
                            class="question d-flex align-center"
                            contain
                            :height="smAndUp ? '40vh' : '20vh'"
                        >
                            <v-card-text>
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                    class="font-weight-light"
                                >
                                    週一回で効果は<br />ありますか？
                                </div>
                            </v-card-text>
                        </v-img>
                        <v-card-text
                            class="d-flex align-center justify-center"
                            :class="
                                smAndUp ? 'arrow_box_top' : 'arrow_box_top_sm'
                            "
                        >
                            <div
                                :class="smAndUp ? 'text-h5' : 'text-caption'"
                                class="font-weight-normal"
                            >
                                生活習慣が変わるので<br />効果があります！
                            </div>
                        </v-card-text>
                    </v-sheet>
                </v-col>
                <v-col
                    cols="6"
                    align="center"
                    class="pa-0 pt-15 mt-15"
                    :class="smAndUp ? '' : 'mb-0'"
                >
                    <v-sheet
                        :style="smAndUp ? 'background-color: #F7FAFD' : ''"
                    >
                        <v-img
                            src="static/landing/sp/icon_q.svg"
                            class="question d-flex align-center"
                            contain
                            :height="smAndUp ? '40vh' : '20vh'"
                        >
                            <v-card-text>
                                <div
                                    :class="smAndUp ? 'text-h4' : 'text-body-1'"
                                    class="font-weight-light"
                                >
                                    なぜこんなに<br />安いんですか？
                                </div>
                            </v-card-text>
                        </v-img>
                        <v-card-text
                            class="d-flex align-center justify-center"
                            :class="
                                smAndUp ? 'arrow_box_top' : 'arrow_box_top_sm'
                            "
                        >
                            <div
                                :class="smAndUp ? 'text-h5' : 'text-caption'"
                                class="font-weight-normal"
                            >
                                テナントや設備を最適化し
                                お客様に還元しています。
                            </div>
                        </v-card-text>
                    </v-sheet>

                    <v-card
                        :style="smAndUp ? 'width: 25vw' : '100%'"
                        :class="smAndUp ? 'ma-16' : 'mt-2'"
                    >
                        <v-img flat :src="imageQuestion" contain />
                    </v-card>
                </v-col>
            </v-row>
        </v-sheet> </v-container
></template>

<script>
export default {
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageQuestion() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/pixta_41575292_XL@2x.png'
                : 'static/landing/sp/qa_img@2x.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.question.v-image ::v-deep .v-image__image {
    background-position: center center !important;
}
.arrow_box_top {
    position: relative;
    background: #cfe7f6f9;
    border: 4px solid #c2e1f5;
    width: 25vw;
    height: 20vh;
}
.arrow_box_top:after,
.arrow_box_top:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_top:after {
    border-color: rgba(207, 231, 246, 0);
    border-bottom-color: #cfe7f6f9;
    border-width: 30px;
    margin-left: -30px;
}
.arrow_box_top:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #c2e1f5;
    border-width: 36px;
    margin-left: -36px;
}

.arrow_box_top_sm {
    position: relative;
    background: #cfe7f6f9;
    border: 1px solid #c2e1f5;
    width: 90%;
    height: 12vh;
}
.arrow_box_top_sm:after,
.arrow_box_top_sm:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_top_sm:after {
    border-color: rgba(207, 231, 246, 0);
    border-bottom-color: #cfe7f6f9;
    border-width: 30px;
    margin-left: -30px;
}
.arrow_box_top_sm:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #c2e1f5;
    border-width: 36px;
    margin-left: -36px;
}
</style>
