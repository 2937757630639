<template>
    <v-container v-if="smAndUp">
        <v-row>
            <v-col>
                <v-breadcrumbs :items="breadCrumbs" divider=">"></v-breadcrumbs>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        breadCrumbs: {
            required: true,
            type: Array,
            default: () => [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'ランディングページ',
                    disabled: true,
                    href: 'personal-training',
                },
            ],
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped></style>
