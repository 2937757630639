var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","align":"center"}},[_c('v-img',{key:_vm.imageVoc1_1,attrs:{"src":_vm.imageVoc1_1,"height":_vm.smAndUp ? '40vh' : '20vh'}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","align":"center"}},[_c('v-img',{key:_vm.imageVoc1_2,staticClass:"trim",attrs:{"src":_vm.imageVoc1_2,"height":_vm.smAndUp ? '40vh' : '20vh'}},[_c('div',{staticClass:"layer"},[_c('div',{class:_vm.smAndUp
                                ? 'inner-text-wrapper'
                                : 'inner-text-wrapper-sm'},[_c('div',{staticClass:"font-weight-normal inner-text",class:_vm.smAndUp ? 'text-h5' : 'text-body-2',attrs:{"align":"left"}},[_c('span',{staticClass:"blue--text"},[_vm._v(" 20代女性 A.Yさん")]),_c('br'),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.smAndUp),expression:"smAndUp"}]}),_c('div',{class:_vm.smAndUp
                                        ? 'text-h5'
                                        : 'text-caption pt-2'},[_vm._v(" 顔が小さくなって、小さな服も着られるようになりました！ ")])])]),_c('div',{class:_vm.smAndUp ? 'blue-line my-6' : 'blue-line-sm'})])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","align":"center"}},[_c('v-img',{key:_vm.imageVoc2_2,staticClass:"trim",attrs:{"src":_vm.imageVoc2_2,"height":_vm.smAndUp ? '40vh' : '20vh'}},[_c('div',{staticClass:"layer"},[_c('div',{class:_vm.smAndUp
                                ? 'inner-text-wrapper'
                                : 'inner-text-wrapper-sm'},[_c('div',{staticClass:"font-weight-normal inner-text",class:_vm.smAndUp ? 'text-h5' : 'text-subtitle-2',attrs:{"align":"left"}},[_c('span',{staticClass:"blue--text"},[_vm._v(" 30代女性 M.Kさん")]),_c('br'),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.smAndUp),expression:"smAndUp"}]}),_c('div',{class:_vm.smAndUp
                                        ? 'text-h5'
                                        : 'text-caption pt-2'},[_vm._v(" 着てみたい服やお出かけしたい場所がたくさん増えました！ ")])])]),_c('div',{class:_vm.smAndUp ? 'blue-line my-6' : 'blue-line-sm'})])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","align":"center"}},[_c('v-img',{key:_vm.imageVoc2_1,attrs:{"src":_vm.imageVoc2_1,"height":_vm.smAndUp ? '40vh' : '20vh'}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","align":"center"}},[_c('v-img',{key:_vm.imageVoc3_1,attrs:{"src":_vm.imageVoc3_1,"height":_vm.smAndUp ? '40vh' : '20vh'}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","align":"center"}},[_c('v-img',{key:_vm.imageVoc3_2,staticClass:"trim2",attrs:{"src":_vm.imageVoc3_2,"height":_vm.smAndUp ? '40vh' : '20vh'}},[_c('div',{staticClass:"layer"},[_c('div',{class:_vm.smAndUp
                                ? 'inner-text-wrapper'
                                : 'inner-text-wrapper-sm'},[_c('div',{staticClass:"font-weight-normal inner-text",class:_vm.smAndUp ? 'text-h5' : 'text-subtitle-2',attrs:{"align":"left"}},[_c('span',{staticClass:"blue--text"},[_vm._v(" 40代女性 A.Mさん")]),_c('br'),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.smAndUp),expression:"smAndUp"}]}),_c('div',{class:_vm.smAndUp
                                        ? 'text-h5'
                                        : 'text-caption pt-2'},[_vm._v(" 40代の女性には絶対にオススメしたいです！ ")])])])]),_c('div',{class:_vm.smAndUp ? 'blue-line my-6' : 'blue-line-sm'})])],1),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"12","md":"6","align":"left"}},[_c('div',{staticClass:"font-weight-light",class:_vm.smAndUp ? 'text-h4 my-4 ' : 'text-h6',attrs:{"align":_vm.smAndUp ? '' : 'center'}},[_c('span',{staticStyle:{"line-height":"1.5em"}},[_vm._v(" 効果が実感できない"),_c('br'),_vm._v("運動を続けられない、"),_c('br'),_vm._v(" カラダを変えられない ")]),_c('br'),_c('br'),_vm._v("そんな"),_c('span',{staticClass:"red--text"},[_vm._v("あなた")]),_vm._v("へ"),_c('br'),_c('br')]),_c('div',{class:_vm.smAndUp ? 'text-body-1 my-4 ' : 'text-body-2 mx-4',style:(_vm.smAndUp ? 'width: 80%;' : '')},[_vm._v(" 経験豊富なREVOISTのパーソナルトレーナーが科学の知見に基づき体の悩みを解決します。 ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6","align":"center"}},[_c('v-img',{key:_vm.imageMethods,attrs:{"src":_vm.imageMethods}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }